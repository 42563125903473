import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "stats-menu"
    }}>{`Stats Menu`}</h1>
    <p>{`During a game, you can press Tab to view the stats of all players.
This screen also displays the team bonus that have been unlocked thanks to the `}<a parentName="p" {...{
        "href": "/poles#bonus-poles"
      }}>{`Bonus Pole`}</a>{`.`}</p>
    <p><img alt="Stats Menu" src={require("../public/tab.jpg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      